import React from "react";
import CVs from "../Files/CandidatuFE.pdf";
import "../App.css";

const CV = () => {
   return (
      <div className="CV">
         <input
            type="text"
            placeholder="Type your email and I will send you my resume"
         />
         <button>Enter</button>
         {/* <iframe
            title="myFrame"
            height="90%"
            width="100%"
            src={CVs + "#zoom=80%"}
         /> */}
      </div>
   );
};

export default CV;
