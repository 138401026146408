import React from "react";
import Tabs from "./Tabs";
import "../CSS/Skills.css";
import { useState, useEffect } from "react";
import { IoLogoJavascript } from "react-icons/io";
import { SiHtml5 } from "react-icons/si";
import { IoLogoCss3 } from "react-icons/io";
import { SiReact } from "react-icons/si";
import { GrMysql } from "react-icons/gr";
import { FaNodeJs } from "react-icons/fa";

const Skills = () => {
   const [react, setReact] = useState("barbg");
   const [html, setHtml] = useState("barbg");
   const [css, setCss] = useState("barbg");
   const [mysql, setMysql] = useState("barbg");
   const [nodejs, setNodejs] = useState("barbg");

   useEffect(() => {
      setTimeout(() => {
         setReact("barbg react");
         setHtml("barbg html");
         setCss("barbg css");
         setMysql("barbg mysql");
         setNodejs("barbg nodejs");
      }, 200);
   }, []);

   return (
      <div className="section">
         <div className="sectionwrapper projectsbackground">
            <Tabs />
            <div className="skills">
               <h1>Progress </h1>
               <br />
               <div className="progress">
                  <div className="progresswrapper">
                     <IoLogoJavascript className="skillicon" />
                     <div className="progressmy">
                        <div className="numeprocentaj">
                           <div className="nume">Javascript / Typescript</div>
                           <div className="procentaj">65%</div>
                        </div>
                        <div className="bar">
                           <div className={react}></div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="progress">
                  <div className="progresswrapper">
                     <SiHtml5 className="skillicon" />
                     <div className="progressmy">
                        <div className="numeprocentaj">
                           <div className="nume">HTML</div>
                           <div className="procentaj">85%</div>
                        </div>
                        <div className="bar">
                           <div className={html}></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="progress">
                  <div className="progresswrapper">
                     <FaNodeJs className="skillicon" />
                     <div className="progressmy">
                        <div className="numeprocentaj">
                           <div className="nume">Nodejs / ExpressJs & PHP</div>
                           <div className="procentaj">65%</div>
                        </div>
                        <div className="bar">
                           <div className={react}></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="progress">
                  <div className="progresswrapper">
                     <IoLogoCss3 className="skillicon" />
                     <div className="progressmy">
                        <div className="numeprocentaj">
                           <div className="nume">CSS</div>
                           <div className="procentaj">90%</div>
                        </div>
                        <div className="bar">
                           <div className={css}></div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="progress">
                  <div className="progresswrapper">
                     <IoLogoJavascript className="skillicon" />
                     <div className="progressmy">
                        <div className="numeprocentaj">
                           <div className="nume">ReactJs & Redux</div>
                           <div className="procentaj">66%</div>
                        </div>
                        <div className="bar">
                           <div className={react}></div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="progress">
                  <div className="progresswrapper">
                     <GrMysql className="skillicon" />
                     <div className="progressmy">
                        <div className="numeprocentaj">
                           <div className="nume">MySQL / MongoDB</div>
                           <div className="procentaj">40%</div>
                        </div>
                        <div className="bar">
                           <div className={mysql}></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Skills;
