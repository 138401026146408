import React from "react";
import Tabs from "./Tabs";
import "../CSS/Projects.css";
import Music from "../images/musicplayer.jpg";
import Cards from "../images/Cards.jpg";
import Game from "../images/game.jpg";
import Kale from "../images/Kale.JPG";
import { FaReact, FaSass } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { SiRedux } from "react-icons/si";

const Projects = () => {
   return (
      <div className="section">
         <div className="sectionwrapper projectsbackground">
            <Tabs />
            <div className="info3">
               <div className="info3left">
                  <h1>#1 Fullstack Blog</h1>
                  <img src={Kale} alt="kaleidoscpe" />
                  <a
                     href="https://puce-fuzzy-elk.cyclic.app/"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="buttons"
                  >
                     Live Project
                  </a>
                  <div className="github">
                     <a
                        href="https://github.com/iamrainonline/frontend"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="buttons"
                     >
                        Frontend Code
                     </a>
                     <a
                        href="https://github.com/iamrainonline/backend"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="buttons"
                     >
                        Backend Code
                     </a>
                  </div>
               </div>
               <div className="info3right">
                  <h1>Technologies Used</h1>
                  <p>
                     <SiRedux color="#0097BD" />
                     MySQL, JWT, ExpressJs, CRUD, REST Api
                  </p>
                  <p>
                     <FaReact className="codename" /> ReactJs
                  </p>
                  <p>
                     <FaSass className="codename" /> Sass
                  </p>

                  <p>
                     This blog is a comprehensive fullstack web application
                     developed using MySQL and Express.js. It uses various
                     functionalities, such as user authentication, CRUD
                     operations, and a RESTful API. The entire application is
                     built from scratch, including the creation of a user
                     authentication system, designing buttons and carousels, and
                     implementing seamless data management.
                     <br /> <br /> Note: Page down due to hosting services
                     prices!
                  </p>

                  <p>
                     <b> Hosting:</b> CloudsClusters (MySQL) , Cyclic.sh
                     (ExpressJs) , Cloudinary (images) & Vercel (React)
                  </p>
               </div>
            </div>
            <div className="info3">
               <div className="info3left">
                  <h1>#2 Music Player</h1>
                  <img src={Music} alt="musicplayer" />
                  <a
                     href="https://rainmelody.netlify.app/"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="buttons"
                  >
                     Live Project
                  </a>
                  <a
                     href="https://github.com/iamrainonline/mymusic"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="buttons"
                  >
                     Check Code
                  </a>
               </div>
               <div className="info3right">
                  <h1>Technologies Used</h1>
                  <p>
                     <FaReact className="codename" /> ReactJs
                  </p>
                  <p>
                     <FaSass className="codename" /> Sass
                  </p>
                  <p>
                     A simple music player application that uses static data.
                     The application is build from scratch, including the player
                     itself, buttons and slider
                  </p>
                  <p>
                     The application can be easily edited into fetching an API
                     of songs/wallpapers. Or querry from a database.
                  </p>
                  <p>Mobile friendly ✓</p>
               </div>
            </div>

            <div className="info3">
               <div className="info3left">
                  <h1>#3 Memory Game</h1>
                  <img src={Cards} alt="Cards" />
                  <a
                     href="https://memorygame4.netlify.app/"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="buttons"
                  >
                     Live Project
                  </a>
                  <a
                     href="https://github.com/iamrainonline/mymusic"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="buttons"
                  >
                     Check Code
                  </a>
               </div>
               <div className="info3right">
                  <h1>Technologies Used</h1>
                  <p>
                     <IoLogoJavascript className="codename" /> Javascript
                  </p>
                  <p>
                     <FaSass className="codename" /> Sass
                  </p>
                  <p>
                     The memory game is a pretty interesting game, and has been
                     around for a while. You are given a set of cards containing
                     images which are turned to the back, when you click on an
                     image, it flips over and reveals the image. If you click on
                     two images that are exactly the same, that is a win for
                     you.
                  </p>
                  <p>
                     A ranking system, WR and PB to be added soon, as well as
                     16x16 grid.
                  </p>
                  <p>Mobile friendly ✓</p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Projects;
