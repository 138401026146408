import { React, useState, useEffect } from "react";
import "../App.css";
import "../CSS/Home.css";
import Typewriter from "typewriter-effect";
import SVG2 from "./SVGs/Secondsvg";
import { IoIosArrowDropupCircle } from "react-icons/io";
const Home = () => {
   const [myScroll, setScroll] = useState(0);
   useEffect(() => {
      window.onscroll = function () {
         var m = window.pageYOffset;
         setScroll(m);
      };
   });

   function ScrollTop() {
      window.scrollTo({ top: 0 });
   }

   function exploreMore() {
      window.scrollTo({ top: 550 });
   }

   return (
      <div className="section">
         <div className={myScroll >= 600 ? "spoiler" : "spoilernone"}>
            <IoIosArrowDropupCircle onClick={ScrollTop} />
         </div>

         <div className="sectionwrapper">
            <div className="info">
               <div className="infoleft">
                  <div className="circle">
                     <div className="circleparent">
                        <div className="circleone"></div>
                        <div className="circletwo"></div>
                     </div>
                  </div>

                  <Typewriter
                     options={{
                        strings: ["Hello", "My name is"],
                        autoStart: true,
                        loop: true,
                     }}
                  />
                  <div className="flyingtext">
                     <h1 className="animate__animated animate__lightSpeedInLeft">
                        Cristian{" "}
                     </h1>
                     <h1 className="animate__animated animate__lightSpeedInLeft">
                        Candidatu <p>Webdeveloper</p>
                     </h1>
                  </div>

                  <button onClick={exploreMore}>Explore more</button>
               </div>
               <div className="inforight">
                  <div>
                     <SVG2 />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Home;
