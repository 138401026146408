import React from "react";
import Tabs from "./Tabs";
import "../CSS/About.css";
import { BsDiscord } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
import { FaConnectdevelop } from "react-icons/fa";
import euTest from "./euTest.jpg";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { IoLogoFacebook } from "react-icons/io";
import { SiLeetcode, SiCodewars } from "react-icons/si";

const About = () => {
   return (
      <div className="section">
         <div className="sectionwrapper projectsbackground">
            <Tabs />
            <div className="about">
               {/* new Profile */}
               <div className="left">
                  <div className="img-wrapper">
                     <img src={euTest} alt="" />
                  </div>
                  <p>
                     Highly motivated, self-starting developer seeking to launch
                     a career building web applications and services. Familiar
                     with development and deployment process for many web-based
                     technology since 2019.
                  </p>

                  <div className="footerright">
                     <span>
                        <a
                           href="https://github.com/iamrainonline"
                           target="_blank"
                        >
                           <AiFillGithub />
                        </a>

                        <a
                           href="https://www.facebook.com/candidatu.cristian/"
                           target="_blank"
                        >
                           <IoLogoFacebook />
                        </a>
                        <a
                           href="https://www.linkedin.com/in/cristian-candidatu-220927181/"
                           target="_blank"
                        >
                           <AiFillLinkedin />
                        </a>
                     </span>
                  </div>
               </div>
               <div className="right">
                  <div className="myskills">
                     <p className="title_info">Working experience</p>
                     <div className="job">
                        <p>Frontend Developer 8.2022 - 6.2023 </p>
                        <a href="https://www.itom.ro/">i-Tom Solutions</a>
                     </div>
                     <div className="job">
                        <p>Fullstack Developer 5.2021 - 9.2022 </p>
                        <a href="https://www.plantanapp.com/">Plant an App</a>
                     </div>
                     <div className="job">
                        <p>Junior Frontend Developer 04.2021 - 09.2021 </p>
                        <a href="https://www.itom.ro/">CodeZilla</a>
                     </div>

                     <p className="title_info">Practice & Solving</p>
                     <div className="job">
                        <p>
                           <SiLeetcode /> LeetCode profile (Algo & DTS){" "}
                        </p>
                        <a href="https://leetcode.com/cristiancandidatu/">
                           LeetCode
                        </a>
                     </div>
                     <div className="job">
                        <p>
                           <SiCodewars /> CodeWars Profile (5 Kyu){" "}
                        </p>
                        <a href="https://www.codewars.com/users/rainonline">
                           CodeWars
                        </a>
                     </div>
                  </div>
                  <div className="circle">
                     <div className="circleparent">
                        <div className="circleone"></div>
                        <div className="circletwo"></div>
                     </div>
                  </div>
               </div>
               {/* end new Profile */}
            </div>
         </div>
         <div className="moreabout">
            <h1>Current Technologies</h1>
            <br />
            <p>
               Technology Stack:{" "}
               <i>
                  <b>
                     TypeScript, Next.js, React, Redux, Redux Toolkit, MongoDB,
                     Express.js, Node.js, REST API, JWT, Cookies, SASS
                  </b>
               </i>
            </p>
            <div className="accordion"></div>
            <h3 className="workingProjects">
               <FaConnectdevelop /> Working on Projects
            </h3>
            <div className="currentProject">
               <h4>#1 Social Network Platform:</h4>
               <div className="circle">
                  <div className="circleparent">
                     <div className="circleone"></div>
                     <div className="circletwo"></div>
                  </div>
               </div>
               <p>
                  <b>
                     Technology Stack: React, Redux Toolkit, Express.js, REST
                     API, JWT, MongoDB, Typescript
                  </b>
                  <br />
                  <br />- Developing a robust and scalable Social Network
                  Platform that allows users to connect, share, and interact
                  with each other. Leveraged TypeScript and Next.js for improved
                  type safety and server-side rendering, ensuring optimal
                  performance and SEO.
                  <br />
                  <br />
                  - The application utilizes Redux and Redux Toolkit for
                  efficient state management, while MongoDB serves as the
                  database for storing user data and social media content.
                  Implemented RESTful APIs to facilitate seamless communication
                  between the front-end and back-end. <br /> <br /> Ensured
                  secure user authentication with JWT and managed user sessions
                  using cookies. <br />- The platform incorporates responsive
                  design with SASS, delivering an engaging user experience
                  across various devices.
               </p>
            </div>
            <div className="currentProject rightproject">
               <h4>#2 Personal Blogging Application:</h4>
               <p>
                  <b>
                     Technology Stack: React, Redux Toolkit, Express.js, REST
                     API, JWT
                  </b>
                  <br />
                  <br />
                  - Created a Personal Blogging Application that enables users
                  to write, publish, and share their thoughts and experiences.
                  React and Express were chosen to ensure safety and optimal
                  performance through server-side rendering. React, and Redux
                  Toolkit are utilized to manage the application's state
                  effectively.
                  <br />
                  <br />
                  - MySQL stores blog posts and user details, while Express.js
                  powers the back-end and RESTful APIs for seamless
                  communication. <br />
                  <br />- Implemented JWT-based user authentication to safeguard
                  user data and manage sessions. The application features a
                  clean and responsive design using SASS, providing an enjoyable
                  reading experience across different devices.
               </p>
            </div>{" "}
            <h3 className="workingProjects">
               <FaConnectdevelop /> Communities, public resources &
               Recommendations
            </h3>
            <div className="currentProjects">
               <div className="circle">
                  <div className="circleparent">
                     <div className="circleone"></div>
                     <div className="circletwo"></div>
                  </div>
               </div>
               <p>
                  <AiFillYoutube size="20" />{" "}
                  <a href="https://www.youtube.com/@developedbyed">
                     Frontend & Design ( devEd )
                  </a>
               </p>
               <p>
                  <AiFillYoutube size="20" />{" "}
                  <a href="https://www.youtube.com/@Dani_Krossing">
                     Backend & Databases ( Dani Krossing )
                  </a>
               </p>
               <p>
                  <AiFillYoutube size="20" />
                  <a href="https://www.youtube.com/@freecodecamp">
                     Data structures & Algorithms ( freeCodeCamp )
                  </a>
               </p>
               <p>
                  <BsDiscord size="20" />

                  <a href="https://discord.gg/8CmXv8YC">React - Reactiflux</a>
               </p>
            </div>
         </div>
      </div>
   );
};

export default About;
