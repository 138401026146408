import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import "../App.css";
import "../CSS/Third.css";
import { FcCheckmark } from "react-icons/fc";
import { FaInfoCircle } from "react-icons/fa";

function ContactForm() {
   const [state, handleSubmit] = useForm("mayvekkn");
   if (state.succeeded) {
      return (
         <h2>
            Your message has been sent
            <FcCheckmark className="checkmark" />
         </h2>
      );
   }
   return (
      <div className="section">
         <div className="contact" id="contact">
            <div className="circle circle-contact">
               <div className="circleparent">
                  <div className="circleone"></div>
                  <div className="circletwo"></div>
               </div>
            </div>
            <h3>
               {" "}
               Contact{" "}
               <span>
                  {" "}
                  <div className="tooltip">
                     <FaInfoCircle className="infocircle" />
                     <span className="tooltiptext">
                        Its a working email form
                     </span>
                  </div>
               </span>
            </h3>

            <div className="thirds">
               <form onSubmit={handleSubmit}>
                  <label htmlFor="email">Email Address *</label>
                  <br />

                  <input
                     required
                     id="email"
                     type="email"
                     name="email"
                     placeholder=" name@example.com"
                  />
                  <ValidationError
                     prefix="Email"
                     field="email"
                     errors={state.errors}
                  />
                  <br />
                  <br />
                  <label htmlFor="message">Send a message *</label>
                  <br />
                  <textarea
                     required
                     id="message"
                     name="message"
                     placeholder="Your message here..."
                  />
                  <ValidationError
                     prefix="Message"
                     field="message"
                     errors={state.errors}
                  />

                  <button type="submit" disabled={state.submitting}>
                     Submit
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}
function App() {
   return <ContactForm />;
}
export default App;
