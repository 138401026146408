import React, { useState, useEffect } from "react";
import "../CSS/Theme.css";
import { FaPaintRoller } from "react-icons/fa";

const Theme = () => {
   const [theme, setTheme] = useState(false);
   const [themeNumber, setThemeNumber] = useState(0);

   useEffect(() => {
      applyTheme();
   }, [themeNumber]);

   const applyTheme = () => {
      const root = document.documentElement;
      switch (themeNumber) {
         case 1:
            // Celestial
            root.style.setProperty("--main-color", "#281F4A");
            root.style.setProperty("--second-color", "#3e3e3e");
            root.style.setProperty("--third-color", "#232222");
            root.style.setProperty("--touch-color", "#ce2828");
            root.style.setProperty("--hover-color", "#211a3a");
            root.style.setProperty("--font-color", "white");
            break;
         case 2:
            // Autumn
            root.style.setProperty("--main-color", "#d9534f");
            root.style.setProperty("--second-color", "#3F3D56");
            root.style.setProperty("--third-color", "#383838");
            root.style.setProperty("--touch-color", "#ff6347");
            root.style.setProperty("--hover-color", "#da8c72");
            root.style.setProperty("--font-color", "#fff");
            break;
         case 3:
            // Moonlight
            root.style.setProperty("--main-color", "#2c3e50");
            root.style.setProperty("--second-color", "#34495e");
            root.style.setProperty("--third-color", "#2c3e50");
            root.style.setProperty("--touch-color", "#3498db");
            root.style.setProperty("--hover-color", "#5dade2");
            root.style.setProperty("--font-color", "white");
            break;
         case 0:
            root.style.setProperty("--main-color", "#111e1f");
            root.style.setProperty("--second-color", "#003140");
            root.style.setProperty("--third-color", "#222127");
            root.style.setProperty("--touch-color", "#0097bd");
            root.style.setProperty("--hover-color", "#525166");
            root.style.setProperty("--font-color", "white");
            break;
         default:
            break;
      }
   };

   return (
      <div className="themes">
         <div
            className="themeBtn"
            onClick={() => {
               setTheme(!theme);
               setThemeNumber(0);
            }}
         >
            {themeNumber === 0 ? "Themes" : "Restore"} <FaPaintRoller />
         </div>
         {theme && (
            <>
               <div className="themeBtn" onClick={() => setThemeNumber(1)}>
                  Celestial{" "}
               </div>
               <div className="themeBtn" onClick={() => setThemeNumber(2)}>
                  Autumn{" "}
               </div>
               <div className="themeBtn" onClick={() => setThemeNumber(3)}>
                  Moonlight
               </div>
            </>
         )}
      </div>
   );
};

export default Theme;
