import React from "react";
import "../CSS/Footer.css";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { IoLogoFacebook } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { FiCloudSnow } from "react-icons/fi";

const Footer = () => {
   return (
      <div>
         <div className="footer">
            <div className="footerwrapper">
               <div className="footerleft">
                  <p className="footerman">{/* <FiCloudSnow /> */}</p>
               </div>
               <div className="footermid">
                  <p>
                     <span>
                        Copyright &copy; 2022 Cristian Candidatu <br />
                     </span>
                     <br />
                  </p>
               </div>
               <div className="footerright">
                  <span>
                     <a href="https://github.com/iamrainonline" target="_blank">
                        <AiFillGithub />
                     </a>
                     <a
                        href="https://www.facebook.com/candidatu.cristian/"
                        target="_blank"
                     >
                        <IoLogoFacebook />
                     </a>
                     <a
                        href="https://www.linkedin.com/in/cristian-candidatu-220927181/"
                        target="_blank"
                     >
                        <AiFillLinkedin />
                     </a>
                  </span>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Footer;
