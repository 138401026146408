import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import CV from "./components/CV";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import About from "./components/About";
import Third from "./components/Third";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Theme from "./components/Theme";

const App = () => {
   return (
      <Router>
         <div>
            <Routes>
               <Route exact path="/*" exact element={<Navbar />} />
               <Route exact path="/*" exact element={<Footer />} />
            </Routes>
            <Routes>
               <Route exact path="/resume" exact element={<CV />} />
            </Routes>
            <Routes>
               <Route
                  exact
                  path="/"
                  element={
                     <>
                        <Home />
                        <Projects />
                        <Third />
                     </>
                  }
               />
               <Route
                  exact
                  path="/doi"
                  element={
                     <>
                        <Home />
                        <About />
                        <Third />
                     </>
                  }
               />
               <Route
                  exact
                  path="/trei"
                  element={
                     <>
                        <Home />
                        <Skills />
                        <Third />
                     </>
                  }
               />
               <Route exact path="/trei" exact element={<Home />} />
            </Routes>
            <Routes>
               <Route
                  exact
                  path="*"
                  element={
                     <>
                        <Footer />
                     </>
                  }
               />
            </Routes>
         </div>
      </Router>
   );
};

export default App;
