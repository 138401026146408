import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";

const Tabs = () => {
   return (
      <div className="tabs" id="portfolio">
         <NavLink to="/">Projects</NavLink>
         <NavLink to="/doi">About</NavLink>
         <NavLink to="/trei">Skills</NavLink>
      </div>
   );
};

export default Tabs;
